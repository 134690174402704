import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { HistoryEdu, Instagram, Email, Phone, Collections } from "@mui/icons-material";
import Carousel from "react-material-ui-carousel";
import { Table, TableCell, TableRow, TableHead, TableBody, styled, FormControl, FormLabel, TextField, Button, Alert } from "@mui/material";
import { useState, useRef } from "react";
import { general_validations } from "../lib/utilities";

const icons = {
	"form": (size) => <HistoryEdu color="secondary" fontSize={size}/>, //style={{minWidth: '40px'}}/>,
	"insta": (size) => <Instagram color="secondary" fontSize={size}/>, //style={{minWidth: '40px'}}/>,
	"email": (size) => <Email color="secondary" fontSize={size}/>, //style={{minWidth: '40px'}}/>,
	"phone": (size) => <Phone color="secondary" fontSize={size}/>, //style={{minWidth: '40px'}}/>,
	"gallery": (size) => <Collections color="secondary" fontSize={size}/> //style={{minWidth: '40px'}}/>,
};

const Text = ({ string, align, text_key, style, font, fontSize, display, boxShadow, textTransform, weight }) => (
	<Typography
		sx={{ my: 2, px: boxShadow ? 2 : 0}}
		component="div"
		key={text_key}
		id={text_key}
		textAlign={align}
		fontStyle={style}
		fontFamily={font}
		display={display}
		boxShadow={boxShadow}
		textTransform={textTransform}
		fontSize={fontSize}
		fontWeight={weight}
	>
		{Array.isArray(string) ? string.map(createContent) : string}
		{text_key.includes('brand') ? <sup>&trade;</sup>: <></>}
	</Typography>
);

const LinkText = ({ text, href, align, justify, display, target, icon, link_key, component, size, icon_size }) => (
	<Link sx={{ my: 2, alignItems: align ?? "inherit", justifyContent: justify ?? "inherit"}} href={href} id={link_key} key={link_key} display={display} target={target}>
		<Typography component={component} color="secondary" display={display} fontSize={size}>
			{icon ? icons[icon](icon_size): null}{(icon ? "\t" : "") + text}
		</Typography>
	</Link>
);

const ImageCard = ({ value, display, card_key }) => (
	<Grid
		container
		textAlign={"center"}
		justifyContent="space-evenly"
		component={Card}
		key={card_key}
		id={card_key}
		sx={{
			display: "flex",
			flexDirection: {xs: "column", sm: "row", md: "row", lg: "row"},
			...display.style
		}}
	>
		<Grid
			item
			xs={12}
			sm={12}
			md={display.md}
			sx={{
				display: { xs: "block", sm: "flex" },
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<CardMedia component="img" image={value.src} alt={value.alt} />
		</Grid>
		<Grid
			item
			component={CardContent}
			xs={12}
			sm={12}
			md={12 - display.md}
			sx={{
				display: { xs: "block", sm: "flex" },
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: display.backgroundColor ?? "inherit"
			}}
		>
			<Typography
				component={display && display.hasOwnProperty('title') && display.title.hasOwnProperty('component') ? display.title.component : "h5"}
				variant={display && display.hasOwnProperty('title') && display.title.hasOwnProperty('variant') ? display.title.variant : "h5"}
				textAlign={"center"}
				fontFamily={display && display.hasOwnProperty('title') && display.title.hasOwnProperty('font') ? display.title.font : "Kalnia"}
				fontStyle={display && display.hasOwnProperty('title') && display.title.hasOwnProperty('style') ? display.title.style : "inherit"}
			>
				{value.title}{value.title === "as you whisk" ? <sup>&trade;</sup> : <></>}
			</Typography>
			{value.subtitle ? (
				<Typography
					component="h6"
					variant="h6"
					color={display && display.hasOwnProperty('subtitle') && display.subtitle.hasOwnProperty('color') ? display.subtitle.color : "grey"}
					textAlign={"center"}
					fontFamily={display && display.hasOwnProperty('subtitle') && display.subtitle.hasOwnProperty('font') ? display.subtitle.font : "inherit"}
					fontStyle={display && display.hasOwnProperty('subtitle') && display.subtitle.hasOwnProperty('style') ? display.subtitle.style : "inherit"}
				>
					{value.subtitle}
				</Typography>
			) : null}
			{createContent(value.content)}
		</Grid>
	</Grid>
);

const ContentGrid = ({ sections, grid_key, display }) => (
	<Grid
		container
		alignItems="stretch"
		textAlign={"center"}
		sx={{background: "transparent"}}
		justifyContent="space-evenly"
		key={grid_key}
		id={grid_key}
	>
		{sections.map((item, index) => (
			<Grid item component={Card} xs={display.xs ?? 12} sm={display.sm ?? 6} md={display.md ?? 4} lg={display.lg ?? 4} key={index} sx={{my: display.my ?? 0.1, border: display.border ?? 0, boxShadow: display.boxShadow ?? 0.1, background: "transparent" }}>
				{item.content.type === "list" ? Section(item) : <CardContent>{Section(item)}</CardContent>}
			</Grid>
		))}
	</Grid>
);

const StyledTableCell = styled(TableCell)({
	paddingLeft: 0,
	paddingRight: 7
  });

const GridTable = ({value, display}) => (
	<Grid sx={{mx: "1rem"}}>
			<Table size={"small"} aria-label="a dense table">
				<TableHead>
					<TableRow>
						{Object.keys(value[0]).map((key,index) => (
							<StyledTableCell key={`${key}-${index}`} align="center">
								{
									index === 0 ? 
									<></> :
									<Typography component={display.component ?? "h5"} variant={display.variant ?? "h5"} textAlign={display.alignment ?? "center"} fontWeight={display.weight ?? "inherit"} fontFamily={display.family ?? "Kalnia"} fontSize={display.size ?? null}>
										{key.replace('_inch','"').replaceAll('_', ' ').trim()}
									</Typography>
								}
							</StyledTableCell>
						))}
					</TableRow>
				</TableHead>
			<TableBody>
				{value.map((row,index) => (
					<TableRow 
						key={`row-${index}`}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
					>
						{Object.values(row).map((key, index) => (
							index === 0 ? 
							<StyledTableCell key={`${key}-${index}`} component="th" scope="row">
								<Typography component={display.component ?? "h5"} variant={display.variant ?? "h5"} textAlign={display.alignment ?? "center"} fontWeight={display.weight ?? "inherit"} fontFamily={display.family ?? "Kalnia"} fontSize={display.size ?? "1.2rem"}>
									{key}
								</Typography>
							</StyledTableCell> :
							<StyledTableCell key={`${key}-${index}`} align="center">
								<Typography textAlign={"center"} fontSize={"1.2rem"}>
									{key}
								</Typography>
							</StyledTableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	  </Grid>
)

const PictureCarousel = ({value, animation, duration, interval}) => (
	<Carousel
		navButtonsProps={{ style: { borderRadius: 0 }}} 
		navButtonsWrapperProps={{ style: { bottom: '0', top: 'unset'}}} 
		navButtonsAlwaysVisible={true}
		NextIcon='>'
		PrevIcon='<'
		animation={animation}
		duration={duration}
		interval={interval}
		sx={{minHeight: "100%", overflowY: 'visible', overflowX: 'clip'}}
	>
		{(value.length && value[0].hasOwnProperty('title')) ? value.map(Section) : value.map(createContent)}
	</Carousel>
)

const Form = ({value, handleSubmit}) => {
	const form = useRef();
	const [formState, setFormState] = useState(value.reduce((p, v) => ({...p, [`${v.name}Error`]: false}), {}));
	const [isFormValid, setIsFormValid] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState('success');
	const [alertMessage, setAlertMessage] = useState('');
	const types = value.reduce((p, v) => ({...p, [v.name]: v.type}), {});

	const validateInputs = () => {
		const values = Object.keys(types).reduce((p, v) => ({...p, [v]: document.getElementById(v).value}), {});
		let isValid = true;
		let currentFormState = {...formState};
		for (var field of Object.keys(types)){
			if(!values[field] || !general_validations[types[field]](values[field])){
				currentFormState[`${field}Error`] = true
				isValid = false;
			}
			else {
				currentFormState[`${field}Error`] = false
			}
		}
		setFormState(currentFormState);
		setIsFormValid(isValid);
	};

	return (
		<Box
            component="form"
            onSubmit={handleSubmit(form, setShowAlert, setAlertType, setAlertMessage)}
			ref={form}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2
            }}
        >
			{value.map((v) => (
				<FormControl key={v.label}>
					<FormLabel sx={{textAlign: "left"}} htmlFor={v.label.toLowerCase()}>{v.label}</FormLabel>
					{
						<TextField
							error={formState[`${v.name}Error`]}
							helperText={formState[`${v.name}Error`] ? v.error : ''}
							id={v.name}
							type={v.type}
							name={v.name}
							placeholder={v.placeholder}
							autoComplete={v.type}
							multiline={v.multiline ?? false}
							onChange={validateInputs}
							rows={v.rows ?? 1}
							required
							fullWidth
							variant="outlined"
							color={formState[`${v.name}Error`] ? 'error' : 'primary'}
							sx={{ ariaLabel: v.ariaLabel ?? "inherit" }}
						/>
					}
				</FormControl>
			))}
			<Button
              type="submit"
              fullWidth
              variant="contained"
			  color="secondary"
			  disabled={!isFormValid}
            >
              	<Typography textAlign={"center"} fontSize={18}>
					Submit
				</Typography>
            </Button>
			{showAlert ? <Alert severity={alertType}>{alertMessage}</Alert> : <></>}
		</Box>
	);
};

const createContent = ({ type, value, key, display, handleSubmit }) =>
	type === "string" ? (
		<Text
			string={value}
			key={key}
			text_key={key}
			align={display.align ?? "center"}
			style={display.style ?? "normal"}
			font={display.font ?? "inherit"}
			fontWeight={display.weight ?? "inherit"}
			variant={display.variant ?? "inherit"}
			component={display.component ?? "inherit"}
			display={display.display ?? ""}
			boxShadow={display.boxShadow ?? 0}
			textTransform={display.textTransform ?? "none"}
			fontSize={display.fontSize ?? 18}
		/>
	) : type === "link" ? (
		<LinkText
			text={value.text}
			href={value.href}
			target={value.target ?? ""}
			icon={value.icon}
			key={key}
			link_key={key}
			align={display.align ?? "inherit"}
			justify={display.justify ?? "inherit"}
			display={display.display ?? "inherit"}
			component={display.component ?? 'p'}
			size={display.size ?? "inherit"}
			icon_size={display.icon_size ?? "medium"}
		/>
	) : type === "list" ? (
		(value.length && value[0].hasOwnProperty('title')) ? value.map(Section) : value.map(createContent)
	) : type === "card" ? (
		<ImageCard value={value} display={display} key={key} card_key={key} />
	) : type === "grid" ? (
		<ContentGrid sections={value} key={key} grid_key={key} display={display}/>
	) : type === "image" ? (
		<Tooltip title={value.text ?? ""} key={`tooltip-${key}`}>
			<CardMedia component="img" key={key} image={value.src} alt={value.alt}/>
		</Tooltip>
	) : type === "carousel" ? (
		<PictureCarousel 
			value={value} 
			animation={display.animation ?? "slide"} 
			duration={display.duration ?? 500} 
			interval={display.interval ?? 4000}
		/>
	) : type === "table" ? (
		<GridTable value={value} display={display}/>
	) : type === "form" ? (
		<Form value={value} handleSubmit={handleSubmit}/>
	) : null;

const Section = ({ title, content, font={}, subtitle=null }, index) => {
	return (content.value ? (
		<Box
			key={index}
			id={title ? title.toLowerCase().replaceAll(' ', '-') : null}
			sx={{
				mt: content.hasOwnProperty('display') && content.display.hasOwnProperty('mt') && content.display.mt ? content.display.mt : (content.type === "image" ? 0 : 5),
				mx: content.hasOwnProperty('display') && content.display.hasOwnProperty('mx') && content.display.mx ? content.display.mx : "inherit",
				px: ['h1', 'h2', 'h3'].includes(font.component) ? "12.5%" : 0,
				background: "transparent",
				height: content.hasOwnProperty('display') && content.display.hasOwnProperty('height') && content.display.height ? content.display.height : "inherit",
			}}
		>
			<Typography id={content.key ?? index} component={font.component ?? "h5"} variant={font.variant ?? "h5"} textAlign={font.alignment ?? "center"} fontWeight={font.weight ?? "inherit"} fontFamily={font.family ?? "Kalnia"} fontSize={font.size ?? null}>
				{title}
			</Typography>
			{
				subtitle ? 
				<Typography id={content.key ? `${content.key}-subtitle`: index} component={font.component ?? "h6"} variant={font.variant ?? "h6"} textAlign={font.alignment ?? "center"} fontWeight={font.weight ?? "inherit"} fontFamily={font.family ?? "Kalnia"} fontSize={font.size ?? null}>
					{subtitle}
				</Typography> :
				null
			}
			{createContent(content)}
		</Box>
	) : (
		<Typography id={content.key ?? index} component={font.component ?? "h5"} variant={font.variant ?? "h5"} textAlign={font.alignment ?? "center"} fontWeight={font.weight ?? "inherit"} fontFamily={font.family ?? "Kalnia"} fontSize={font.size ?? null}
			sx={{
				mt: content.hasOwnProperty('display') && content.display.hasOwnProperty('mt') && content.display.mt ? content.display.mt : (content.type === "image" ? 0 : 5),
				mx: content.hasOwnProperty('display') && content.display.hasOwnProperty('mx') && content.display.mx ? content.display.mx : "inherit",
				px: ['h1', 'h2', 'h3'].includes(font.component) ? "12.5%" : 0,
				background: "transparent",
				height: content.hasOwnProperty('display') && content.display.hasOwnProperty('height') && content.display.height ? content.display.height : "inherit"
			}}
			key={content.key}
		>
			{title}
		</Typography>
	));
};

export default Section;
