import emailjs from '@emailjs/browser';

const get_title_case = (string) => string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' ');

export const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => { images[get_title_case(item.replace('./', '').replaceAll('_', ' ').split('.')[0])] = r(item); return item;});
  return images;
}

export const get_image_content = ([name, img]) => ({
  content: {
    type: "image",
    key: name.toString().replaceAll(' ', '-'),
    value: {
      src: img,
      alt: name.toString(),
      text: name.toString()
    }
  }
});

export const general_validations = {
  'email': (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
  'name': (name) => /^[a-zA-Z]{2}[\sa-zA-Z]*$/.test(name) && name.length >= 2,
  'mobile': (mobile) => /^(04|\+614)?([0-9]{8})$/.test(mobile),
  'message': (message) => message.length >= 10
};

export const sendEmail = (form, setShowAlert, setAlertType, setAlertMessage) => (e) => {

  e.preventDefault();

  emailjs
    .sendForm('asyouwhisk_service', 'asyouwhisk_enquiry', form.current, {
      publicKey: 'yBcDQKaVLWU3V98uL',
      privateKey: 'vNQOkx5QX6-pIaludqJ4Z'
    })
    .then(
      () => {
        console.log('SUCCESS!');
        setShowAlert(true);
        setAlertType('success');
        setAlertMessage('Enquiry sent successfully.')
      },
      (error) => {
        console.log('FAILED...', error);
        setShowAlert(true);
        setAlertType('error');
        setAlertMessage("Failed to send enquiry due to technical issue. Please reach us via contact email/number instead. Apologies for the inconvenience.")
      }
    ).finally(() => {
      form.current.reset();
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);

    });
};