import Container from "@mui/material/Container";
import Section from "../components/Section";
import { christmas_range_content } from "../content/christmas_range";
import SEO from "../components/SEO";
import image from '../images/christmas_range/painted_mini_cake_gift_box.webp';
const ChristmasRange = () => (<>
	<SEO
		creator="Shyamal & Jayashree"
		type="website"
		url="https://asyouwhisk.com.au/christmas_range"
		image={image}
		title="Christmas Range"
		description="Christmas Range - Choose from the many christmas delights available for pre-order from our special Christmas Range."		
	/>
	<Container  maxWidth={false} disableGutters sx={{ my: 8.5}}>{christmas_range_content.map(Section)}</Container>
</>);

export default ChristmasRange;