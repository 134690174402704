import {importAll} from '../lib/utilities';
const christmas_range = importAll(require.context('../images/christmas_range', false, /\.(webp)$/));

const double_image_content = [
    {
        title: "",
        images: ["Painted Mini Cake", 'Painted Cake Box'],
        text: {
            value: "The perfect ornament to your Christmas dinner table, this cake is hand-painted with Australian native flora. It’s elegant enough to draw praise but demure enough to not pull focus from your party. Serves 6-8 people.",
            title: "Painted Mini Cake",
            subtitle: "$90",
        }
    },
    {
        title: "",
        images: ["Vintage Mini Cake", 'Vintage Cake Box'],
        text: {
            value: "Nostalgic with ornate piping makes this cake the representation of “more is more”. Perfect for the celebration that wants a splash of colour. Serves 6-8 people.",
            title: "Vintage Mini Cake",
            subtitle: "$75"
        }
    },
    {
        title: "",
        images: ["Painted Macarons", 'Painted Macaron Box'],
        text: {
            value: "These macaron boxes are a truly unique gift for friends and family. Ask us about our flavours of the month to choose from. Sold as box of six, single flavour.",
            title: "Painted Macaron Box",
            subtitle: "$24"
        }
    },
    {
        title: "",
        images: ["Christmas Cupcakes", 'Cupcake Box'],
        text: {
            value: "Christmas themed assorted cupcake box with trees and wreaths. One complimentary upgrade - painted cupcake with “Merry Christmas” included in every box. Choose one flavour per box. Sold by the dozen, single flavour.",
            title: "Christmas Cupcake Box",
            subtitle: "$70"
        }
    }
];

const card_content = [
    {
        title: "",
        image: "Cookies And Cake Box",
        text: {
            type: "list",
            value: [
                "6 of our assorted Christmas themed cupcakes, one of which will be a complimentary upgrade - painted cupcake with “Merry Christmas”. Choose one flavour.",
                "12 cookies of up to 2 flavours of your choice.",
                "Sold as box of Six Cupcakes and 12 Cookies."
            ],
            title: "Cookies and Cake Box",
            subtitle: "$72"
        }
    },
    {
        title: "",
        image: "Everything Deluxe Box",
        text: {
            type: "list",
            value: [
                "6 of our assorted Christmas themed cupcakes, one of which will be a complimentary upgrade -  painted cupcake with “Merry Christmas”. Choose one flavour.",
                "6 cookies. Choose one flavour.",
                "6 Christmas themed painted macarons. Choose one flavour."
            ],
            title: "Everything Deluxe Box",
            subtitle: "$82"
        }
    }
];

export const christmas_range_content = [
    {
        title: "Christmas Range",
        key: "christmas-range-section",
        font: {component: "h2", variant: "h2", size: 30},
        content: {
            type: "link",
            key: "preorder-link",
            display: { mt: 10, align: "center", justify: "center", display: "flex", component: 'h4', size: 25, icon_size: "large"},
            value: {
                icon: "form",
                text: "PRE-ORDER HERE",
                href: "https://docs.google.com/forms/d/1CfGnGLA-9eMEaUGDafcwj0vvY2TjfsJ8liSIiq2FrfE/prefill",
                target: "_blank",
            },
        },
    },
    {
        key: "christmas-range-text-section",
        content: {
            type: "string",
            key: "preorder-link",
            display: { align: "center", fontSize: 22},
            value: "Check out our menu for our standard flavour options. For macarons, ask us about the flavours of the month."
        },
    },
    {
		content: {
			type: "grid",
			key: "christmas-flavours-grid",
			display: { align: "center", xs: 12, sm: 6, md: 4 },
			value: [
				{
					title: "NEW CHRISTMAS FLAVOUR ALERT!",
					content: {
						type: "list",
						display: { align: "center"},
						key: "christmas-flavours-list",
						value: [
							{
								type: "string",
								key: "gingerbread-cake-and-cupcake",
								display: { align: "center", justify: "center", display: "block" },
								value: "Gingerbread Cake and Cupcake",
							},
							{
								type: "string",
								key: "christmas-spice-cookies",
								display: { align: "center", justify: "center", display: "block" },
								value: "Christmas Spice Cookies",
							},
							{
								type: "string",
								key: "christmas-spice-macarons",
								display: { align: "center", justify: "center", display: "block" },
								value: "Christmas Spice Macarons",
							},
						],
					},
				}
			],
		},
	}
].concat(double_image_content.map((crc, index) => (
    {
        title: crc.title,
		content: {
			type: "grid",
			key: `${crc.title.toLowerCase().replaceAll(' ', '-')}-grid`,
			display: { align: "center", mt: index ? 2 : 10, xs: 12, sm: 6, md: 4, boxShadow: "none", border:"none" },
			value: [
				{
                    content: {
                        type: "image",
                        key: `${crc.images[0].toLowerCase().replaceAll(' ', '-')}-image`,
                        value: {
                            src: christmas_range[crc.images[0]],
	                        alt: crc.images[0],
                        },
                    },
		        },
                {
                    
					content: {
                        type: "image",
                        key: `${crc.images[1].toLowerCase().replaceAll(' ', '-')}-image`,
                        value: {
                            src: christmas_range[crc.images[1]],
                            alt: crc.images[1],
                        },
                    },
                },
                {
                    title: crc.text.title,
                    subtitle: crc.text.subtitle,
                    font: {family: "inherit"},
                    content: {
                        type: "string",
                        key: `${crc.title.toLowerCase().replaceAll(' ', '-')}-text`,
                        display: { align: "justify"},
                        value: crc.text.value
                            
                    },
                }
            ]
        }
	}
)), card_content.map(cc => ({
    title: cc.title,
    content: {
        type: "card",
        key: `${cc.title.toLowerCase().replaceAll(' ', '-')}-card`,
        display: {
            flexDirection: "row-reverse",
            md: 4,
            style: { border: "none", boxShadow: "none" },
            title: { style: "inherit", font: "inherit" },
        },
        value: {
            src: christmas_range[cc.image],
            alt: cc.image,
            title: cc.text.title,
            subtitle: cc.text.subtitle,
            content: {
                type: cc.text.type ?? "string",
                key: `${cc.title.toLowerCase().replaceAll(' ', '-')}-${cc.text.type ?? "text"}`,
                display: { align: "justify" },
                value: cc.text.type === 'string' ? cc.text.value : cc.text.value.map((v, idx) => ({
                    type: "string",
                    key: `${cc.title.toLowerCase().replaceAll(' ', '-')}-text-${idx}`,
                    display: { align: "center" },
                    value: v
                }))
            },
        }
    }
})));
